@font-face {
    font-family: 'Satoshi';
    src: url('../../static/fonts/Satoshi-Black.woff2') format('woff2'),
        url('../../static/fonts/Satoshi-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../static/fonts/Satoshi-Bold.woff2') format('woff2'),
        url('../../static/fonts/Satoshi-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../static/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../../static/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../../static/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../../static/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


* {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Open Sans' !important;
    background-color: #191E2C !important;
    position: relative;
}

html {
    scroll-behavior: smooth;

}

body,
textarea,
input {
    padding: 0;
    margin: 0;
    line-height: 1.5;
}

a {
    display: inline-block;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    color: #fff;
    font-weight: bold;
    font-family: 'Satoshi';
}

h1,
h2 {
    font-size: 58px;
    line-height: 1.35;
    font-weight: 900;
}

h3 {
    font-size: 32px;
    line-height: 1.2;
}

p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 18px;
    color: #fff;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

img {
    max-width: 100%;
}

.common-btn {
    background: linear-gradient(94.04deg, #1399D7 0%, #1055CB 100.13%);
    border-radius: 28px;
    font-weight: 600;
    color: #fff !important;
    font-size: 18px;
    padding: 14px 30px;
    min-width: 220px;
    text-align: center;
    transition: all ease-in-out 0.2s;
}

.common-btn:hover {
    background: linear-gradient(94.04deg, #1055CB 0%, #1399D7 100.13%);
    transform: translateY(-2px);
}

body .wrapper {
    overflow: hidden;
}

/* Header */

header {
    padding: 30px 0;
}


header .navbar {
    padding: 0 !important;
}



/* Casino Intro */

.casino-intro {
    padding: 80px 0 100px;
    position: relative;
}

.casino-intro:before {
    left: 0;
    position: absolute;
    content: '';
    background-image: url('../../static/images/banner-design-pattern.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 200%;
    z-index: -1;
    top: -190px;
}

.casino-intro .content {
    max-width: 600px;
}

.casino-intro h1 {
    margin-bottom: 32px;
    max-width: 600px;
}

.casino-intro img {
    width: 783px;
    margin-left: -200px;
    max-width: inherit;
}

.casino-intro p {
    margin-bottom: 40px;
    max-width: 480px;
}

/* Mint */

.mint {
    position: relative;
    padding: 235px 0;
}

.mint:before {
    position: absolute;
    content: '';
    background-image: url('../../static/images/mint.png');
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: luminosity;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
}

.mint .row {
    align-items: center;
    position: relative;
}

.mint .row:before {
    position: absolute;
    content: '';
    width: 815px;
    height: 346px;
    left: -25px;
    bottom: -320px;
    background-image: url('../../static/images/royal-text.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.mint.vending .row:before {
    width: 1015px;
    height: 346px;
    left: -25px;
    bottom: -325px;
    background-image: url('../../static/images/vending-text.png');
}

.mint .container {
    position: relative;
}

.mint .cards-deck-images .diamond {
    position: absolute;
    top: -80px;
    left: 350px;
}

.mint .cards-deck-images .spades {
    position: absolute;
    top: 24px;
    left: -82px;
}

.mint .cards-deck-images .hearts {
    position: absolute;
    bottom: 0;
    left: 45%;
}

.mint .content {
    max-width: 600px;
}

.mint h2 {
    margin-bottom: 40px;
}

.mint .nft {
    background: rgba(25, 30, 44, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
    padding: 48px;
}

.mint .nft .info {
    background: #101524;
    backdrop-filter: blur(5px);
    border-radius: 32px;
    padding: 18px 32px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.mint .nft .info span {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.mint .nft .info a {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.mint .nft .info.quantity {
    padding: 4px;
}

.mint .nft .info.quantity span {
    width: 52px;
    height: 52px;
    min-width: inherit;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transform: translateY(0) !important;
    cursor: pointer;
}

.mint .nft .info.quantity .input-wrap {
    width: calc(100% - 151px);
    display: flex;
    max-width: 250px;
}

.mint .nft .info.quantity input {
    background: transparent;
    width: calc(100% - 104px);
    padding: 0 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    border: 0;
    outline: 0;
    box-shadow: none;
    text-align: center;
}

.mint .nft .info.quantity input::-webkit-input-placeholder {
    /* Edge */
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
}

.mint .nft .info.quantity input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
}

.mint .nft .info.quantity input::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.mint .nft .info.quantity button {
    min-width: inherit;
    width: 139px;
    transform: translateY(0) !important;
    cursor: pointer;
    padding: 8px 15px;
    border: 0;
    box-shadow: none;
    outline: 0;
}

.mint .nft>button {
    border: 0;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    display: block;
    margin: 48px auto 0;
}



/* games */

.games {
    padding: 115px 0 75px;
    position: relative;
}

.games .head {
    margin-bottom: 64px;
}

.games .container {
    position: relative;
    z-index: 3;
}

.games .head {
    position: relative;
    text-align: center;
}

.games .head img {
    position: relative;
    position: absolute;
    left: 0;
    top: 0;
}

.games .head h2 {
    margin-bottom: 32px;
}

.games .game-box {
    background: linear-gradient(94.04deg, #1399D7 0%, #1055CB 100.13%);
    backdrop-filter: blur(2px);
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
    margin-bottom: 60px;
}

.games .row {
    margin: 0 -26px
}

.games .row .col-md-4 {
    padding: 0 26px;
}

.games .game-box .head-wrap {
    display: flex;
    margin-bottom: 32px;
}

.games .game-box .img-wrap img {
    width: 100%;
    transition: all ease-in-out 1s;
}

.games .game-box:hover .img-wrap img {
    transform: scale(1.15);
}

.games .game-box .heading-wrap {
    width: 60px;
    position: relative;
}

.games .game-box .img-wrap {
    width: calc(100% - 60px);
    overflow: hidden;
    border-radius: 16px 0;
}

.games .game-box h3 {
    text-transform: uppercase;
    color: #FFFFFF;
    transform: rotate(-90deg);
    position: absolute;
    width: 210px;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    bottom: -60px;
    line-height: 60px;
}

.games .game-box .bottom {
    padding-left: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.games .game-box .bottom img {
    height: 88px;
    object-fit: contain;
}

.games .game-box a {
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #ffffff;
    font-family: 'Open Sans';
    transition: all ease-in-out 0.2s;
}

.games .game-box a:hover {
    transform: translateY(-2px);
}


/* play-in-metaverse */

.play-in-metaverse {
    padding-top: 122px;
    background: #12151F;
}

.play-in-metaverse .content {
    padding-bottom: 90px;
}

.play-in-metaverse .img-wrap {
    position: relative;
}

.play-in-metaverse .img-wrap:before {
    position: absolute;
    bottom: -300px;
    content: '';
    width: 1126px;
    height: 1308px;
    background-image: url('../../static/images/metaverse-img-bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    right: -230px;
}

.play-in-metaverse .img-wrap img {
    width: 100%;
    max-width: 440px;
    position: relative;
    z-index: 2;
}

.play-in-metaverse .content {
    position: relative;
    z-index: 2;
}

.play-in-metaverse .content img {
    position: absolute;
    max-width: initial;
    top: -140px;
    left: -33px;
    z-index: -1;
}

.play-in-metaverse h2 {
    margin-bottom: 32px;
}

.play-in-metaverse p {
    margin-bottom: 73px;
}


/* Footer */

footer {
    text-align: center;
    padding: 16px 0;
    background: rgba(12, 0, 28, 0.6);
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
}

footer p {
    font-weight: 600;
}

footer .copyright p{
    margin: 0;
}


@media screen and (min-width:1400px) {
    .container {
        max-width: 1230px;
    }
}

@media screen and (max-width:1600px) {
    .mint {
        padding: 115px 0;
    }

    .mint .row:before {
        bottom: -200px;
    }

    .games {
        padding: 96px 0 50px;
        position: relative;
    }
}

@media screen and (max-width:1400px) {

    h1,
    h2 {
        font-size: 54px;
    }

    .casino-intro {
        padding: 50px 0 100px;
    }

    .casino-intro .content {
        max-width: 550px;
    }

    .casino-intro img {
        width: 653px;
        margin-left: -170px;
    }

    .mint {
        background-size: cover;
    }

    .mint .cards-deck-images .spades {
        left: -25px;
    }

    .mint .row:before {
        width: 695px;
        height: 186px;
        bottom: -130px;
    }

    .games {
        padding: 65px 0 50px;
    }

    .play-in-metaverse {
        padding-top: 80px;
    }

    .mint .nft {
        padding: 30px;
    }

    .mint .nft>button {
        margin-top: 30px;
    }

    .play-in-metaverse .content img {
        width: 780px;
        top: -114px;
    }
}

@media screen and (max-width:1199px) {

    h1,
    h2 {
        font-size: 48px;
        line-height: 1.3;
    }

    h3 {
        font-size: 28px;
    }

    .casino-intro .content {
        max-width: 500px;
    }

    .casino-intro p {
        max-width: 400px;
    }

    .casino-intro img {
        width: 543px;
        margin-left: -130px;
    }

    .play-in-metaverse .img-wrap:before {
        width: 900px;
        height: 1018px;
        right: -130px;
        bottom: -200px;
    }

    .mint .nft {
        padding: 15px;
    }

    .mint .row:before {
        width: 595px;
        height: 156px;
    }

    .mint .nft .info {
        padding: 12px;
    }

    .mint .nft .info.quantity button {
        width: 88px;
    }

    .mint .nft .info.quantity .input-wrap {
        width: calc(100% - 100px);
    }

    .mint .nft .info span {
        font-size: 16px;
    }

    .mint .nft .info a {
        font-size: 16px;
    }

    .mint .nft .info.quantity input {
        padding: 0 3px;
        width: calc(100% - 96px);
        font-size: 16px;
    }

    .mint .nft .info.quantity span {
        width: 48px;
        height: 48px;
    }

    .mint .nft .info.quantity input::-webkit-input-placeholder {
        /* Edge */
        font-size: 16px;
    }

    .mint .nft .info.quantity input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 16px;
    }

    .mint .nft .info.quantity input::placeholder {
        font-size: 16px;
    }

    .games .game-box a {
        font-size: 26px;
    }

    .play-in-metaverse .content img {
        width: 660px;
        top: -97px;
    }
}

@media screen and (max-width:991px) {
    .casino-intro {
        text-align: center;
    }

    .casino-intro .content {
        max-width: 550px;
        margin: 0 auto 50px;
    }

    .casino-intro p {
        max-width: 500px;
        margin: 0 auto 40px;

    }

    .casino-intro img {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: block;
        position: relative;
        left: 25px;
    }

    .casino-intro:before {
        height: 100%;
    }

    .mint {
        text-align: center;
        background: none;
        padding: 90px 0;
    }

    .mint .content {
        margin: 0 auto 40px;
    }

    .mint .img-wrap {
        display: block;
        width: 400px;
        margin: 0 auto;
        max-width: 100%;
    }

    .mint .row:before {
        width: 375px;
        height: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -105px;
    }

    .mint.vending .row:before{
        width: 715px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .games .row .col-md-4 {
        padding: 0 15px;
    }

    .games .row {
        margin: 0 -15px;
    }

    .games .game-box .heading-wrap {
        width: 40px;
    }

    .games .game-box .img-wrap {
        width: calc(100% - 40px);
    }

    .games .game-box h3 {
        bottom: -40px;
        line-height: 40px;
    }

    .games .game-box .bottom img {
        height: 58px;
    }

    .games .game-box .bottom {
        padding-left: 15px;
    }

    .games .game-box a {
        font-size: 22px;
    }

    h3 {
        font-size: 22px;
    }

    .play-in-metaverse {
        text-align: center;
    }

    .play-in-metaverse .row {
        flex-direction: column-reverse;
    }

    .play-in-metaverse .content {
        max-width: 600px;
        margin: 0 auto;
    }

    .play-in-metaverse .img-wrap:before {
        right: 0;
        left: -200px;
        right: auto;
    }

    .play-in-metaverse .content img {
        width: 500px;
        top: -85px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .play-in-metaverse p {
        margin-bottom: 40px;
    }

    .mint .nft {
        padding: 48px;
    }

    .mint .nft .info {
        padding: 18px 32px;
    }

    .mint .nft .info.quantity button {
        width: 151px;
    }

    .mint .nft .info.quantity .input-wrap {
        width: calc(100% - 139px);
    }

    .mint .nft .info span {
        font-size: 18px;
    }

    .mint .nft .info a {
        font-size: 18px;
    }

    .mint .nft .info.quantity input {
        padding: 0 8px;
        width: calc(100% - 104px);
        font-size: 18px;
    }

    .mint .nft .info.quantity span {
        width: 52px;
        height: 52px;
    }

    .mint .nft .info.quantity input::-webkit-input-placeholder {
        /* Edge */
        font-size: 16px;
    }

    .mint .nft .info.quantity input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 16px;
    }

    .mint .nft .info.quantity input::placeholder {
        font-size: 16px;
    }
}


@media screen and (max-width: 767px) {
    header .container {
        max-width: 100%;
    }

    h1,
    h2 {
        font-size: 45px;
    }

    .casino-intro {
        padding: 50px 0 80px;
    }

    .games .game-box {
        max-width: 400px;
        margin: 0 auto 60px;
        border-radius: 20px;
    }

    .games .game-box .img-wrap {
        width: calc(100% - 60px);
    }

    .games .game-box .heading-wrap {
        width: 60px;
    }

    .games .game-box a {
        font-size: 26px;
    }

    .games .game-box .bottom {
        padding-left: 15px;
    }

    .games .game-box .bottom img {
        height: 78px;
    }

    .games .game-box h3 {
        bottom: -60px;
        line-height: 60px;
    }

    .games .head img {
        right: 0;
        margin: 0 auto;
    }

    .mint.vending .row:before{
        width: 520px;
        bottom: -265px;
    }

    .mint.vending {
        padding: 170px 0 135px;
    }
}

@media screen and (max-width: 575px) {
    .mint.vending{
        margin-top: -175px;
    }

    .mint.vending .row:before{
        width: 340px;
    }

    .container {
        padding: 0 30px;
    }

    header .navbar {
        flex-direction: column;
    }

    header .navbar .logo {
        margin-bottom: 30px;
    }

    h1,
    h2 {
        font-size: 34px;
    }

    .casino-intro img {
        left: 10px;
    }

    .mint .nft {
        padding: 15px;
    }

    .mint .nft .info {
        padding: 12px;
    }

    .mint .nft .info.quantity button {
        width: 88px;
    }

    .mint .nft .info.quantity .input-wrap {
        width: calc(100% - 100px);
    }

    .mint .nft .info span {
        font-size: 16px;
    }

    .mint .nft .info a {
        font-size: 16px;
    }

    .mint .nft .info.quantity input {
        padding: 0 3px;
        width: calc(100% - 96px);
        font-size: 16px;
    }

    .mint .nft .info.quantity span {
        width: 48px;
        height: 48px;
    }

    .mint .nft .info.quantity input::-webkit-input-placeholder {
        /* Edge */
        font-size: 16px;
    }

    .mint .nft .info.quantity input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 16px;
    }

    .mint .nft .info.quantity input::placeholder {
        font-size: 16px;
    }

    .mint .row:before {
        width: 315px;
    }

    .play-in-metaverse .content img {
        width: 315px;
    }
}